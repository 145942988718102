if (!window.localStorage.getItem("AssessmentDownloadScoreSettingsCleaned")) {
  window.localStorage.setItem("AssessmentDownloadScoreSettingsCleaned", "true");
  window.localStorage.setItem("AssessmentDownloadScoreSettings", JSON.stringify({ selectedProfiles: [] }));
}

function getCookie(name) {
  const value = "; " + document.cookie;
  const parts = value.split("; " + name + "=");
  if (parts.length === 2) return parts.pop().split(";").shift();
}

function parseCookie() {
  const splitCookie = document.cookie.split(";");

  if (splitCookie.length === 1) {
    return {};
  }

  return splitCookie
    .map((s) => s.split("="))
    .reduce((acc, [key, v]) => {
      acc[decodeURIComponent(key.trim())] = decodeURIComponent(v.trim());
      return acc;
    }, {});
}

function backToCookieString(cookieObject, domainAndPath) {
  const keyValues = [];
  for (let key in cookieObject) {
    const value = cookieObject[key];
    keyValues.push([key, value]);
  }
  return keyValues
    .map((s) => `${s.join("=")};`)
    .flatMap((value, index, array) => [value, domainAndPath])
    .join("");
}

function setCookie(name, value) {
  const host = window.location.host;
  let domain = host;
  if (!domain.includes("localhost")) {
    const domainParts = host.split(".");
    domainParts.shift();
    domain = "." + domainParts.join(".");
  }
  const domainAndPath = `domain=${domain};path=/;`;
  const toAdd = `${name}=${value};${domainAndPath}`;
  const cookiesObject = parseCookie();
  cookiesObject[name] = value;
  document.cookie = backToCookieString(cookiesObject, domainAndPath);
}

function loadScript(src, callback, id, onError, type) {
  const scriptElement = document.createElement("script");
  scriptElement.src = src;
  scriptElement.type = type ? type : "text/javascript";
  scriptElement.async = false;
  scriptElement.defer = false;
  if (id && typeof id === "string") {
    scriptElement.id = id;
  }
  if (callback && typeof callback === "function") {
    scriptElement.onload = callback;
  }
  if (onError && typeof onError === "function") {
    scriptElement.onerror = onError;
  }
  document.getElementsByTagName("head")[0].appendChild(scriptElement);
}

function forceShowScrollbars() {
  const revoContent = document.getElementById("revo-content");
  if (revoContent) {
    revoContent.classList.add("force-show-scrollbars");
  }
  return !!revoContent;
}

document.addEventListener(
  "DOMContentLoaded",
  function() {
    const LanguageCookieName = "SETTINGS_LANGUAGE";
    const lang = queryParams.get("lang");
    if (lang && lang !== "") {
      sessionStorage.setItem(LanguageCookieName, lang);
    }
  },
  false
);

window.loadScript = loadScript;
const queryParams = new URLSearchParams(window.location.search);

// Report rendering with token passed in query string
const token = queryParams.get("token");
if (token && token !== "") {
  const decodedToken = decodeURIComponent(token);
  setCookie("TS_AUTH", decodedToken);
  window.twentyone.token = token;
}

const authScopesBase64 = getCookie("TS_AUTH_SCOPES");

function areScrollbarsVisible() {
  const scrollableElem = document.createElement("div"),
    innerElem = document.createElement("div");
  scrollableElem.style.width = "30px";
  scrollableElem.style.height = "30px";
  scrollableElem.style.overflow = "scroll";
  scrollableElem.style.borderWidth = "0";
  innerElem.style.width = "30px";
  innerElem.style.height = "60px";
  scrollableElem.appendChild(innerElem);
  document.body.appendChild(scrollableElem); // Elements only have width if they're in the layout
  const diff = scrollableElem.offsetWidth - scrollableElem.clientWidth;
  document.body.removeChild(scrollableElem);
  return diff > 0;
}

window.addEventListener("load", function() {
  // Show scrollbars if they're hidden.
  if (!areScrollbarsVisible()) {
    const interval = window.setInterval(function() {
      if (forceShowScrollbars()) {
        window.clearInterval(interval);
      }
    }, 1);
  }
});

